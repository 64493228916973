function printPage() {
    window.print();
}

function init(container: Element | Document = document) {
    container.querySelectorAll('.js-print-page').forEach(el => {
        el.addEventListener('click', printPage);
    });
}

const _module = { init };

export default _module;
