import { Sketch } from './sketch.js';
import { webglObserver } from '../utils/execute-on-intersection';

let webglSketch: any;

export default function webglEffect(container: HTMLElement | Document = document) {
    const stockSlider = container.querySelector<HTMLElement>('.js-stock-slider');
    if (stockSlider && !stockSlider.classList.contains('is-initialized-slider')) {
        webglObserver(
            stockSlider,
            webglSketch,
            () => {
                webglSketch = new Sketch({
                    element: stockSlider,
                    uniforms: {
                        width: {value: 0.5, type:'f', min:0, max:10},
                    }
                });

                stockSlider.classList.add('is-initialized-slider')
            },
            { rootMargin: '200px 0px 200px 0px' },
        );
    }
}


