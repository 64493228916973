import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

export function createInitialBaseTimeline() {
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 1.2, ease: 'cubic-bezier(.25,.46,.45,.94)' },
        onComplete: () => {
            document.documentElement.classList.add('initial-animation-complete');
        },
    });

    const fitsrtTitle = document.querySelector('.a-title');

    let splittedParent, splitted;
    if (fitsrtTitle) {
        splittedParent = new SplitText(fitsrtTitle, {
            type: 'lines',
            linesClass: 'title-line-parent',
        });

        splitted = new SplitText(fitsrtTitle.getElementsByClassName('title-line-parent'), {
            type: 'lines',
        });
    }

    tl.to('.a-header', {
        opacity: 1,
    })
        .to(
            '.a-title',
            {
                opacity: 1,
                stagger: 0.3,
            },
            '-=0.8',
        )
        .fromTo(
            splitted?.lines || {},
            { yPercent: 100 },
            {
                yPercent: 0,
                duration: 0.8,
                onComplete: function () {
                    splittedParent?.revert();
                    splitted?.revert();
                },
            },
            '-=0.5',
        )
        .fromTo(
            '.a-descr',
            {
                opacity: 0,
                y: 20,
            },
            {
                opacity: 1,
                y: 0,
            },
            '-=0.8',
        )
        .to(
            '.a-images',
            {
                opacity: 1,
                stagger: 0.3,
            },
            '-=0.5',
        )
        .to(
            fitsrtTitle,
            {
                opacity: 1,
                duration: 0.1,
            },
            0,
        );

    return tl;
}
