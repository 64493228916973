import {setFirstSlide, playFirstSlide} from './main-slider';
import copySlider from './copy-slider';
import headerTheme from './header-theme';
import reveal from './reveal';
import splittedText from '../animations/splitted-text';
import { lazyload } from './lazy-load';

const cloneSectionObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                playFirstSlide();
                window.scrollTo({ top: 0, behavior: 'auto' });
                requestAnimationFrame(() => {
                    splittedText.reinit();
                    reveal.reinit();
                });
            }
        });
    },
    { rootMargin: '0px 0px -100% 0px' },
);

const cloneSectionObserverStart = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setFirstSlide();
            }
        });
    },
    { rootMargin: '0px 0px 0px 0px' },
);

function init(container: HTMLElement | Document = document) {
    const firstBlock = container.querySelector('.js-first-block');
    const footer = container.querySelector('.js-footer');


    if (footer && firstBlock) {
        const footerClone = footer.cloneNode(true) as HTMLElement;
        footerClone.classList.add('is-show');
        firstBlock.parentElement?.appendChild(footerClone);
        lazyload.update();
        reveal.init();
    }

    if (firstBlock) {
        const blockClone = firstBlock.cloneNode(true) as HTMLElement;
        blockClone.classList.remove('js-webgl-slider');
        blockClone.classList.add('js-webgl-slider-copy');
        firstBlock.parentElement?.appendChild(blockClone);
        cloneSectionObserverStart.observe(blockClone);
        cloneSectionObserver.observe(blockClone);
        copySlider();
        headerTheme.init();
    }
}

const _module = { init };

export default _module;
