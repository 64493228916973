import Lazyload from 'vanilla-lazyload';

export const lazyload = new Lazyload({
    elements_selector: '.lazy',
    threshold: 900,
    callback_loaded: (element) => {
        if (element.classList.contains('js-webgl-img')) {
            element.dispatchEvent(new Event('loaded'));
        }
    },
});
