let header: HTMLElement | null;

export function lockScroll () {
    header = document.querySelector<HTMLElement>('.js-header');

    const getScrollBarWidth = () => {
        const outer = document.createElement('div');

        outer.style.cssText = `
            position: absolute;
            top: -9999px;
            width: 50px;
            height: 50px;
            overflow: scroll;
            visibility: hidden;
        `;

        document.body.appendChild(outer);
        const scrollbarWidth = outer.offsetWidth - outer.clientWidth;
        document.body.removeChild(outer);

        return scrollbarWidth;
    }

    if (document.body.scrollHeight > window.innerHeight) {
        document.body.style.paddingRight = `${getScrollBarWidth()}px`;
    } else {
        document.body.style.paddingRight = ``;
    }

    document.body.style.overflow = 'hidden';

    if (header) {
        header.style.width = `calc(100% - ${getScrollBarWidth()}px)`;
    }
}

export function unlockScroll () {
    document.body.style.paddingRight = ``;
    document.body.style.overflow = '';

    if (header) {
        header.style.width = `100%`;
    }
}
