import './LitPopup.scss';
import { LitElement } from 'lit';
import LitPopup from 'lit-popup';
import { getCSSCustomProp } from '../../utils/css';
import { lockScroll, unlockScroll } from '../../components/lockScroll';

export interface LitPopupElement {
    instance: LitPopup;
    _wasBodyLocked: boolean;
    _leaveTimeout: NodeJS.Timeout;
}

const header = document.querySelector<HTMLElement>('.js-header');
const mainPage = document.querySelector<HTMLElement>('.js-main');
const footer = document.querySelector<HTMLElement>('.js-footer');

const popupWidthDefault = getComputedStyle(document.documentElement).getPropertyValue('--popup-width');
const contactsMapWrapper = document.querySelector('.js-map-wrapper');

const NO_SCROLL_CLASS = 'no-scroll';

export class LitPopupElement extends LitElement {
    constructor() {
        super();
        this._wasBodyLocked = false;
    }

    static get properties() {
        return {
            name: {
                type: Boolean,
            },
            opened: {
                type: String,
            },
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        const name = this.getAttribute('data-lit-popup');

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        this.instance = new LitPopup(name, {
            onOpen: () => {
                clearTimeout(this._leaveTimeout);
                if (
                    this.classList.contains('is-exits-right') ||
                    this.classList.contains('is-menu') ||
                    this.classList.contains('is-exits-left')
                ) {
                    this._exitsDocument();
                }
                this._lockBodyScroll();
            },
            onOpenComplete: () => {
                const focusableOnOpenElement = this.renderRoot.querySelector<HTMLElement>('[data-focus-on-popup-open]');
                setTimeout(() => focusableOnOpenElement?.focus({ preventScroll: true }), 50);
            },
            onClose: () => {
                this._unlockBodyScroll();
                if (
                    this.classList.contains('is-exits-right') ||
                    this.classList.contains('is-exits-full') ||
                    this.classList.contains('is-menu') ||
                    this.classList.contains('is-exits-left')
                ) {
                    this._unExitsDocument();
                }
            },
        });
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);

        if (name === 'opened') {
            if (typeof newVal === 'string') {
                this.instance.open();
            } else {
                this.instance.close();
            }
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._leaveTimeout);

        if (this.instance) {
            this.instance.destroy();
        }
    }

    open() {
        this.instance?.open();
    }

    close() {
        this.instance?.close();
    }

    protected _lockBodyScroll() {
        lockScroll();
    }

    protected _unlockBodyScroll() {
        unlockScroll();
    }

    protected _exitsDocument() {
        if (this.classList.contains('is-full')) {
            mainPage?.classList.add('is-exist-full');
            footer?.classList.add('is-exist-full');
            const contactsUsPopup = document.querySelector('[data-lit-popup="contacts-us"]');

            if (contactsUsPopup) contactsUsPopup.inert = false;
            if (!this.classList.contains('no-header')) {
                header?.classList.add('is-exits-full');
            } else {
                if (header) header.inert = false;
            }
        } else if (this.classList.contains('is-exits-left')) { //проверка попапа в контактах
            setTimeout(() => {
                this._unlockBodyScroll(); //при откртии попапа в контактах разрешаем скролл
            }, 0);
            contactsMapWrapper?.classList.remove('is-exits-map');
        } else {
            const exitsClass = this.classList.contains('is-menu') ? 'is-exits-menu' : 'is-exits';
            mainPage?.classList.add(exitsClass);
            footer?.classList.add(exitsClass);

            if (!this.classList.contains('no-header')) {
                header?.classList.add(exitsClass);
            } else {
                if (header) header.inert = false;
            }
        }
    }

    protected _unExitsDocument() {
        header?.classList.remove('is-exits', 'is-exits-menu', 'is-exits-full');
        mainPage?.classList.remove('is-exits', 'is-exits-menu', 'is-exits-full');
        footer?.classList.remove('is-exits', 'is-exits-menu', 'is-exits-full');
        contactsMapWrapper?.classList.add('is-exits-map');
    }
}

customElements.define('app-lit-popup', LitPopupElement);

declare global {
    interface HTMLElementTagNameMap {
        'app-lit-popup': LitPopupElement;
    }
}
