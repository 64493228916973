function changeInput(this: HTMLInputElement) {
    if (this.value.length >= 1) {
        this.classList.add('is-not-empty');
    } else {
        this.classList.remove('is-not-empty');
    }
}

function init(container: HTMLElement | Document = document) {
    const inputsOfForm = container.querySelectorAll<HTMLInputElement>('.js-input');

    // если инпут заполнен, лэйбел находится наверху
    inputsOfForm.forEach((input) => {
        input.addEventListener('change', changeInput);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-input')).forEach((el) => {
        if (el) {
            el.removeEventListener('change', changeInput);
        }
    });
}

const _module = { init, destroy };

export default _module;
