import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const timelinesMap = new WeakMap<Element, gsap.core.Tween>();

function init(container: HTMLElement | Document = document) {
    container.querySelectorAll<HTMLElement>('.js-parallax').forEach((el) => {
        const parallaxContainer = el.closest('.js-parallax-container');
        const tween = gsap.to(el, {
            yPercent: -15,
            scrollTrigger: {
                trigger: parallaxContainer,
                start: 'top bottom-=100',
                end: 'bottom top+=100',
                scrub: true,
            },
        });
        timelinesMap.set(el, tween);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-parallax')).forEach((el) => {
        const tween = timelinesMap.get(el);

        if (tween) {
            tween.kill();
            timelinesMap.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
