import gsap from 'gsap';
import { CountUp } from 'countup.js';
import { lockScroll, unlockScroll } from '../components/lockScroll';

// расстояние между картинками
let imageGap: number;
if (matchMedia('(max-width: 576px').matches) {
    imageGap = 20;
} else if (matchMedia('(max-width: 1440px').matches) {
    imageGap = 40;
} else {
    imageGap = 60;
}

export const preloaderAnimationDuration = 0.7; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const counterContainer = document.querySelector<HTMLElement>('.js-preloader-counter-container');
    const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
    const mainImage = document.querySelector<HTMLElement>('.js-preloader-main-image');
    const mainImageInner = mainImage?.querySelector<HTMLElement>('.js-preloader-main-image-inner');
    const otherImages = document.querySelectorAll<HTMLElement>('.js-preloader-image');
    let loaded = false;

    const state = {
        completed: false,
    };

    lockScroll();

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            unlockScroll();

            document.dispatchEvent(new Event('preloader-leave'));

            if (!loaded) {
                loaded = true;
            }

            const tl = gsap.timeline();

            tl.to(counterContainer, { duration: 0.9, opacity: 0 })
                .to(
                    preloader,
                    {
                        duration: 1,
                        opacity: 0,
                        onComplete: () => {
                            preloader?.classList.add('is-hidden');
                            state.completed = true;
                        },
                    },
                    1,
                )
                .add(resolve, 0.65);
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('canplay', () => resolve(), { once: true });
                }
            }
        });
    }

    const counterInstance = counter
        ? new CountUp(counter, 0, {
            startVal: 0,
            useEasing: true,
            duration: 1.05,
        })
        : null;

    function setPercent(value: number) {
        counterInstance?.update(value);
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'));
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

        if (images.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    function imagesAnimation() {
        if (mainImage && mainImageInner && otherImages.length > 3) {
            const { clientHeight, clientWidth } = otherImages[0] as HTMLElement;

            // соотношения размеров остальных картинок к главной
            const widthKoef = clientWidth / mainImage.clientWidth;
            const heightKoef = clientHeight / mainImage.clientHeight;

            // установить размеры главной картинки, как у других
            const scaleMainImage = widthKoef > heightKoef ? widthKoef : heightKoef;
            const sizeMainImage = widthKoef > heightKoef ? mainImage.clientHeight : mainImage.clientWidth;
            const sizeOtherImage = widthKoef > heightKoef ? clientHeight : clientWidth;
            const widthMainPhotoWithScale = sizeMainImage * scaleMainImage;
            const percentClipPath = ((widthMainPhotoWithScale - sizeOtherImage) / 2) / widthMainPhotoWithScale * 100;

            const clipPathMainImage = widthKoef > heightKoef ?
                `polygon(0% ${percentClipPath}%, 100% ${percentClipPath}%, 100% ${100 - percentClipPath}%, 0% ${100 - percentClipPath}%)` :
                `polygon(${percentClipPath}% 0%, ${100 - percentClipPath}% 0%, ${100 - percentClipPath}% 100%, ${percentClipPath}% 100%)`;

            gsap.set(mainImage, {
                scale: scaleMainImage,
                y: '-60vh',
                "clip-path": clipPathMainImage
            })

            // сдвинуть остальные картинки, чтоб они стояли в ряд
            otherImages[0].style.transform = `translate(calc(50vw - 50% - ${imageGap * 2}px + 5px), 0)`;
            otherImages[1].style.transform = `translate(calc(50vw - 50% - ${imageGap}px), 0)`;
            otherImages[2].style.transform = `translate(calc(-50vw + 50% + ${imageGap}px), 0)`;
            otherImages[3].style.transform = `translate(calc(-50vw + 50% + ${imageGap * 2}px - 5px), 0)`;

            gsap.set(otherImages[1], { y: '60vh', });
            gsap.set(otherImages[2], { y: '60vh', });

            setTimeout(() => {
                const tl = gsap.timeline({
                    defaults: {
                        duration: 2.5,
                        ease: 'Power4.easeInOut'
                    }
                });
                tl
                    .to(mainImage, {
                        opacity: 1,
                        duration: 1.2
                    })
                    .to(otherImages, {
                        opacity: 1,
                        duration: 1.2,
                    }, 0)
                    .to(mainImage, {
                        y: 0,
                        duration: 2.5,
                    }, '-=1.4')
                    .to(otherImages, {
                        y: 0
                    }, '-=2.4')
            }, 300);

            setTimeout(() => {
                const tl = gsap.timeline({
                    defaults: {
                        duration: 2,
                        ease: 'Power.easeOut'
                    }
                });
                tl
                    .to(mainImage, {
                        scale: 1,
                        "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
                    })
                    .to(otherImages[0], {
                        x: `-${imageGap * 2}px`,
                    }, 0)
                    .to(otherImages[1], {
                        x: `-${imageGap}px`,
                    }, 0)
                    .to(otherImages[2], {
                        x: `${imageGap}px`,
                    }, 0)
                    .to(otherImages[3], {
                        x: `${imageGap * 2}px`,
                    }, 0)
            }, 2200);
        }
    }

    async function loadAssets() {
        imagesAnimation();
        await loadAssetsFromElement(document.body);
        setPercent(100);
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
