export const fetchSwiper = () => import(/* webpackChunkName: "swiper" */ 'swiper');
export const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);
export const fetchScrollToPlugin = () =>
    import(
        'gsap/ScrollToPlugin'
        /* webpackChunkName: "gsap-scrollto-plugin" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );
