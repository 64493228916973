import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { executeOnIntersection } from '../utils/execute-on-intersection';
const selector = '.js-reveal-mask';

const splitMap = new WeakMap<HTMLElement, gsap.core.Timeline>();
const splitParentMap = new WeakMap<HTMLElement, any>();
const splitLinesMap = new WeakMap<HTMLElement, any>();

function splittedText(el: HTMLElement, speedKoef = 1) {
    el.classList.add('splittable');

    const splittedParent = new SplitText(el, {
        type: 'lines',
        linesClass: 'title-line-parent',
    });

    const splitted = new SplitText(el.getElementsByClassName('title-line-parent'), {
        type: 'lines',
    });

    const tl = gsap.timeline({
        defaults: { duration: 1 * speedKoef, ease: 'cubic-bezier(.25,.46,.45,.94)' },
        paused: true,
        onStart: () => {
            el.classList.add('splittable--initialized');
        },
    });

    tl.fromTo(
        splitted.lines,
        { yPercent: 100 },
        {
            yPercent: 0,
            duration: 0.8 * speedKoef,
            onComplete: function () {
                splittedParent.revert();
                splitted.revert();
            },
        },
    );

    splitMap.set(el, tl);
    splitParentMap.set(el, splittedParent);
    splitLinesMap.set(el, splitted);

    return tl;
}

function reinit(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));

    setTimeout(() => {
        elements.forEach((el) => {
            el.classList.remove('splittable--initialized');
            el.classList.remove('splittable');

            const splitTween = splitMap.get(el);
            const splitParentTween = splitParentMap.get(el);
            const splitLinesTween = splitLinesMap.get(el);

            if (splitParentTween) {
                splitParentTween.revert();
                splitParentMap.delete(el);
            }

            if (splitLinesTween) {
                splitLinesTween.revert();
                splitLinesMap.delete(el);
            }

            if (splitTween) {
                splitTween.kill();
                splitMap.delete(el);
            }
        });
    }, 1);

    setTimeout(() => {
        init();
    }, 2);
}

function init() {

    Array.from(document.querySelectorAll<HTMLElement>(selector)).forEach((el) => {
        const tl = splittedText(el);
        executeOnIntersection(el, () => tl.play(), { threshold: 0.8 });
    });
}

const _module = { init, reinit };

export default _module;
