import { webglObserver } from '../utils/execute-on-intersection';
import { Sketch } from './sketch.js';

let webglSketch: any;

export default function webglEffect(container: HTMLElement | Document = document) {
    const copySlider = container.querySelector<HTMLElement>('.js-webgl-slider-copy');
    if (copySlider) {
        initCopySlider();
    }

    function initCopySlider() {
        if (copySlider && !copySlider.classList.contains('is-initialized-slider')) {
            webglObserver(
                copySlider,
                webglSketch,
                () => {
                    webglSketch = new Sketch({
                        element: copySlider,
                        uniforms: {
                            width: {value: 0.5, type:'f', min:0, max:10},
                        }
                    });

                    copySlider.classList.add('is-initialized-slider');
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    }

}
