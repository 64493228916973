import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';

import headerTheme from './inits/header-theme';
import { lazyload } from './inits/lazy-load';
import inputs from './inits/inputs';
import ajaxForms from './inits/ajax-forms';
import vhMobileFix from '../modules/vh-mobile-fix';

import mainSlider from './inits/main-slider';
import infoSlider from './inits/info-slider';
import stockSlider from './inits/stock-slider';
import inputMasks from './inits/masks';
import scrollToEl from './inits/scrollto';
import { scrollTo } from './inits/scrollto';
import menu from './inits/menu';
import { createInitialBaseTimeline } from './animations/initial-base';
import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';
import firstBlock from './inits/first-block';
import splittedText from './animations/splitted-text';
import revealAnimations from './inits/reveal';
import parallaxEffect from './inits/parallax';
import bookletsDropdown from './inits/booklets-dropdown';
import dropdownCitiesButtons from './inits/cities-dropdown';
import collectionsPopups from './inits/collections-popups';
import { YandexMap } from './inits/yandexMap';
import printPage from './inits/print-page';
import textPageSlider from './inits/text-page-slider';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    headerTheme.init();
    lazyload.update();
    revealAnimations.init();
    scrollToEl.init();
    menu.init();
    firstBlock.init();
    splittedText.init();
    mainSlider();
    vhMobileFix();
    bookletsDropdown.init();
    dropdownCitiesButtons.init();
    collectionsPopups.init();
    customElements.define('app-yandex-map', YandexMap);

    setTimeout(() => {
        window.scrollTo({ top: 0 });
    }, 100);

    const windowUrl = window.location.pathname;
    const newWindowUrl = windowUrl.replace('/html/', '').replace('/', '');
    const topPosition = window.scrollY;

    if (topPosition > 1) document.documentElement.classList.add('hide-preloader');

    let preloadLeaveTime = 2600;

    if (newWindowUrl !== '' || topPosition > 1) {
        preloadLeaveTime = 1000;
        document.documentElement.classList.add('hide-preloader');
    }

    preloader
        .loadAssets()
        .then(() => timeout(preloadLeaveTime))
        .then(() => preloader.leave())
        .then(() => {
            const animation = createInitialBaseTimeline();
            animation.play();

            stockSlider();
            infoSlider();
            parallaxEffect.init();
            printPage.init();
            textPageSlider.init();

            window.addEventListener('keydown', (event) => {
                if (event.code == 'End') {
                    event.preventDefault();
                    const footer = document.querySelector<HTMLElement>('.js-footer.is-show');
                    if (footer) scrollTo(footer);
                }
            });

            const subscribePopupEl = document.querySelector<LitPopupElement>(
                'app-lit-popup[data-lit-popup="subscribe"]',
            );
            if (subscribePopupEl) {
                ajaxForms.init(subscribePopupEl);
                inputs.init(subscribePopupEl);
                inputMasks.init(subscribePopupEl);
            }

            const bookletPopupEl = document.querySelector<LitPopupElement>(
                'app-lit-popup[data-lit-popup="get-booklet"]',
            );
            if (bookletPopupEl) {
                ajaxForms.init(bookletPopupEl);
                inputs.init(bookletPopupEl);
                inputMasks.init(bookletPopupEl);
            }

            const contactsUsPopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="contacts-us"]');
            if (contactsUsPopupEl) {
                ajaxForms.init(contactsUsPopupEl);
                inputs.init(contactsUsPopupEl);
                inputMasks.init(contactsUsPopupEl);
            }

            const contactsFormEl = document.querySelector('.js-contacts-form');
            if (contactsFormEl) {
                ajaxForms.init(contactsFormEl);
                inputs.init(contactsFormEl);
                inputMasks.init(contactsFormEl);
            }
        });
});

// Always at the end
module.hot?.accept();
