import { Sketch } from './sketch.js';
import { webglObserver } from '../utils/execute-on-intersection';

let webglSketch: any;

export default function webglEffect(container: HTMLElement | Document = document) {
    const mainSlider = container.querySelector<HTMLElement>('.js-webgl-slider');

    if (mainSlider && !mainSlider.classList.contains('is-initialized-slider')) {
        webglObserver(
            mainSlider,
            webglSketch,
            () => {
                webglSketch = new Sketch({
                    element: mainSlider,
                    autoplay: true,
                    uniforms: {
                        width: {value: 0.5, type:'f', min:0, max:10},
                    }
                });

                mainSlider.classList.add('is-initialized-slider');
            },
            { rootMargin: '200px 0px 200px 0px' },
        );
    }
}

export function setFirstSlide() {
    if (webglSketch) {
        webglSketch.setFirstSlide();
    }
}

export function playFirstSlide() {
    if (webglSketch) {
        webglSketch.playFirstSlide();
    }
}
