import gsap from 'gsap';
import { getOffsetTop } from '../utils/dom';
import { fetchScrollToPlugin } from '../dynamic-modules';

const SELECTOR = '[data-scrollto]';

let elements: HTMLElement[] = [];

export async function scrollTo(el: Element) {
    const y = getOffsetTop(el);
    const { ScrollToPlugin } = await fetchScrollToPlugin();
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, { duration: 1, scrollTo: y, ease: 'cubic-bezier(.25,.46,.45,.94)' });
}

async function onClick(this: HTMLElement, event: Event) {
    event.preventDefault();
    const selector = this.dataset.scrollto;

    if (!selector) {
        throw new Error(`[scrollto] ${SELECTOR} is missing on the element.`);
    }

    const elementScrollTo = document.querySelector(selector);

    if (elementScrollTo) {
        scrollTo(elementScrollTo);
    } else if (this instanceof HTMLAnchorElement && this.href) {
        window.location.href = this.href;
    }
}

function init() {
    elements = Array.from(document.querySelectorAll<HTMLElement>(SELECTOR));
    elements.forEach((el) => {
        el.addEventListener('click', onClick);
    });

    if (window.location.hash) {
        const elementScrollTo = document.querySelector(window.location.hash);
        const url = window.location.href.split('#')[0];

        if (elementScrollTo) {
            scrollTo(elementScrollTo);
            history.pushState(null, "", url);
        }
    }
}

function destroy() {
    elements.forEach((el) => {
        el.removeEventListener('click', onClick);
    });

    elements = [];
}

const _module = { init, destroy };

export default _module;
