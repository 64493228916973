import { LitElement, html } from 'lit';
import loadScript from '../../modules/load-script';
import addresesData from './database-contacts';

const targetCardAddress = document.querySelectorAll('.js-contacts-button');
const citiesList = document.querySelectorAll('.js-collection-cities');
let selectedCity = document.querySelector('.js-button-text-city');
const contactsMapPopup = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="contacts-map"]'); // prettier-ignore

export class YandexMap extends LitElement {
    constructor() {
        super();

        this._closeCard = this._closeCard.bind(this);
        this.speedOfAnimation = 300;
        this.instance = null;
        this.zoom = 12;
        this.maxZoom = 17;
        this.minZoom = 4;
        this._isFetching = false;
        this.currentCoords = [0, 0];
        this.data = addresesData;
        this.marks = [];
        this.activeTabs = [];
        this.clusterer;
        this.isCardVisible = false;

        this._observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    this._init();
                }
            });
        });
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            data: {
                type: Object,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
            isCardVisible: {
                type: Boolean,
            },
        };
    }

    createRenderRoot() {
        return this;
    }

    _init() {
        this._error = null;
        this._isFetching = true;
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.apiKey}&lang=ru_RU`)
            .then(() => {
                const { ymaps } = window;
                ymaps.ready(() => {
                    const _this = this;
                    const margin = window.matchMedia('(max-width: 1024px)').matches ? 50 : [50, 50, 50, 400];
                    this.instance = new ymaps.Map(
                        this.renderRoot.querySelector('.map'),
                        {
                            center: [55.751574, 37.573856],
                            zoom: this.zoom,
                            margin: margin,
                        },
                        {
                            minZoom: this.minZoom, // Минимальный зум
                            maxZoom: this.maxZoom, // Максимальный зум
                        },
                    );
                    this.instance.container.fitToViewport();
                    this.instance.controls
                        .remove('mapTools')
                        .remove('typeSelector')
                        .remove('searchControl')
                        .remove('trafficControl')
                        .remove('miniMap')
                        .remove('scaleLine')
                        .remove('routeEditor')
                        .remove('smallZoomControl')
                        .remove('geolocationControl')
                        .remove('rulerControl');

                    // Добавляем обработчик события "wheel" на карту
                    this.instance.events.add('wheel', (e) => {
                        // Проверяем, удерживается ли клавиша Ctrl
                        if (!e.get('ctrlKey')) {
                            e.preventDefault();
                        }
                    });

                    const elements = [];

                    document.addEventListener('DOMContentLoaded', function () {
                        const closeBtn = document.querySelector('.card-close-btn');
                        closeBtn.addEventListener('click', close);
                    });

                    if (this.data) {
                        if (this.data[0].addresses[0].coordOfAddress) {
                            this.currentCoords = this.data[0].addresses[0].coordOfAddress;
                        }

                        this.data.forEach((company) => {
                            company.addresses.forEach(({ coordOfAddress, address, city, phone }) => {
                                const iconLayout = ymaps.templateLayoutFactory.createClass(
                                    `
                                        <div class="map-placemark__icon" data-coords="${coordOfAddress}">
                                            <svg class="map-placemark__icon-svg" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18" cy="18" r="18" fill="#71836B"/>
                                            </svg>
                                        </div>
                                    `,
                                );
                                const animatedLayout = ymaps.templateLayoutFactory.createClass(
                                    `<div class="map-placemark js-map-placemark" data-coords="${coordOfAddress}">
                                            <div class="map-placemark__icon">
                                                <svg class="map-placemark__icon-svg" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="18" cy="18" r="18" fill="#71836B"/>
                                                </svg>
                                            </div>
                                            <div class="map-card">
                                                <button
                                                    class="card-close-btn"
                                                    aria-label="Закрыть"
                                                >
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 1L11 11M1 11L11 1" stroke="#3B3B3B"/>
                                                    </svg>
                                                </button>
                                                <div class="map-card__inner">
                                                <div class="contacts__item-wrapper__info">
                                                    <div class="contacts__item-name text-lg">${company.company}</div>
                                                    <div class="contacts__item-block__wrapper">
                                                        <div class="contacts__item-title text-m">Адрес</div>
                                                            <div class="contacts__item-info">${
                                                                address ? address : city
                                                            }</div>
                                                    </div>
                                                    <div class="contacts__item-block__wrapper">
                                                        <div class="contacts__item-title text-m ${
                                                            phone || company.website ? '' : 'is-hide'
                                                        }">Контакты</div>
                                                        <div class="contacts__item-contacts__info">
                                                            <div class="contacts__item-info ${phone ? '' : 'is-hide'}">
                                                                ${phone}
                                                            </div>
                                                            <a href="https://${
                                                                company.website
                                                            }" rel="noreferrer" target="_blank" class="link-cross contacts__item-info ${
                                        company.website ? '' : 'is-hide'
                                    }">
                                                                ${company.website}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`,
                                    {
                                        build() {
                                            animatedLayout.superclass.build.call(this);
                                            const element =
                                                this.getParentElement().getElementsByClassName('js-map-placemark')[0];
                                            elements.push(element);

                                            const card = element.querySelector('.map-card');
                                            card?.classList.add('is-active');

                                            let Cardwidth = card.offsetWidth;
                                            let Cardheight = card.offsetHeight;
                                            let CardTop = card.offsetTop;
                                            let CardLeft = card.offsetLeft;

                                            this.getData().options.set('shape', {
                                                type: 'Rectangle',
                                                coordinates: [
                                                    [CardLeft, CardTop],
                                                    [CardLeft + Cardwidth, CardTop + Cardheight],
                                                ],
                                            });
                                        },
                                    },
                                );
                                const markProperties = {};
                                const markOptions = {
                                    iconLayout: iconLayout,
                                    iconShape: { type: 'Circle', coordinates: [12, 12], radius: 24 },
                                    balloonLayout: animatedLayout,

                                    hideIconOnBalloonOpen: false,
                                    hasBalloon: true,
                                };
                                const mark = (window.Placemark = new ymaps.Placemark(
                                    coordOfAddress,
                                    markProperties,
                                    markOptions,
                                ));
                                this.marks.push(mark);
                                this.instance.geoObjects.add(mark);

                                mark.events.add('balloonopen', (mark) => {
                                    selectedCity.innerHTML = city;
                                    this._setCompanies(city); // фильтруем города в попапе
                                    const coords =
                                        mark.originalEvent.currentTarget.geometry._map.action._map._bounds[0];
                                    const closeBtn = document.querySelector('.card-close-btn');
                                    closeBtn?.addEventListener('click', () => {
                                        this._closeCard();
                                    });
                                });
                            });
                        });

                        this.dispatchEvent(new Event('init'));

                        this.clusterer = new ymaps.Clusterer({
                            clusterIconLayout: ymaps.templateLayoutFactory.createClass(`<div class="map-placemark">
                            <div class="map-placemark__icon">
                                <svg class="map-placemark__icon-svg" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="18" fill="#71836B"/>
                                </svg>
                                <div class="map-placemark__number">{{ properties.geoObjects.length }}</div>
                            </div>`),

                            // Чтобы метка была кликабельной, переопределим ее активную область.
                            clusterIconShape: {
                                type: 'Rectangle',
                                coordinates: [
                                    [0, 0],
                                    [80, 80],
                                ],
                            },
                            hasBalloon: false,
                            hasHint: false,
                            gridSize: 128,
                            suppressMapOpenBlock: true,
                        });

                        this.clusterer.add(this.marks);
                        this.instance.geoObjects.add(this.clusterer);

                        //Позже поправлю функцию
                        // this.clusterer.events.add('click', (e) => {
                        //     const cluster = e.get('target');

                        //     const geoObjects = cluster.getGeoObjects();
                        //     const currentZoom = this.instance.getZoom();
                        //     console.log('Текущий зум:', currentZoom);

                        //     // Проверяем количество меток в кластере
                        //     if (geoObjects.length >= 2) {
                        //         // Выполняем необходимые действия, например, вызываем функцию для отображения контента этих меток
                        //         // В данном примере я просто вывожу количество меток в консоль
                        //         contactsMapPopup.open();

                        //         console.log(`Кластер содержит ${geoObjects.length} меток.`);
                        //     } else {
                        //         // Если меток в кластере меньше двух, можно выполнить другие действия, если необходимо
                        //     }
                        // });
                    }

                    this.instance.events.add('click', this._closeCard);
                    const mapLinks = document.querySelectorAll('.js-map-link');
                    mapLinks.forEach((link) => link.addEventListener('click', () => this._setCoords(link)));
                });

                targetCardAddress.forEach((btn) => {
                    btn.addEventListener('click', this._getTargetCardMark);
                });

                citiesList.forEach((elem) => {
                    elem.addEventListener('click', this._getTargetCity);
                });
            })
            .catch((err) => {
                this._error = err;
                throw err;
            })
            .finally(() => {
                this._isFetching = false;
            });

        const widthMap = document.querySelector('.map-container');
        const resize = new window.ResizeObserver(() => this._onResize());
        resize.observe(widthMap);

        selectedCity.innerHTML = 'Москва';
        this._setCompanies(selectedCity.innerHTML);
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.apiKey) {
            throw new Error('API key not provided.');
        }

        this._observer.observe(this);
    }

    attributeChangedCallback(name, oldVal, newVal) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._observer.disconnect();
        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    _onResize() {
        this.instance?.container.fitToViewport();
    }

    _renderMap() {
        if (this._isFetching) {
            return html`<div class="map-loader">Загружаем карту...</div>`;
        }

        if (this._error) {
            return html`<div>${this._error.message}</div>`;
        }

        return '';
    }

    _closeCard() {
        const closeBtn = document.querySelector('.card-close-btn');
        closeBtn?.parentNode.classList.add('close-balloon');
        setTimeout(() => {
            this.instance.balloon.close();
        }, this.speedOfAnimation);
    }

    _openCard(coords) {
        this.marks.forEach((elem) => {
            const coordElem = elem.geometry._coordinates;
            if (coords === coordElem) {
                setTimeout(() => {
                    elem.balloon.open();
                }, 500);
            }
        });
    }

    _hoverPoint(coords) {
        setTimeout(() => {
            const placemarksOnMap = document.querySelectorAll('.js-map-placemark');
            placemarksOnMap.forEach((mark) => {
                const markCoords = mark.dataset.coords ? mark.dataset.coords.split(',') : [];
                if (markCoords.length && markCoords[0] == coords[0] && markCoords[1] == coords[1]) {
                    mark.classList.add('is-hovered', 'is-active');

                    // показываем попап
                    const pointPopup = mark.querySelector('.map-card');
                    if (pointPopup) pointPopup.classList.add('is-active');

                    // сдвигаем карту чуть ниже и левее, чтобы было видно поп ап
                    if (window.matchMedia('(max-width: 767px)').matches) {
                        if (this.instance) {
                            this.instance.setCenter([Number(coords[0]) + 0.05, Number(coords[1]) + 0.08]);
                        }
                    }
                } else {
                    mark.classList.remove('is-hovered');
                }
            });
        }, 200);
    }
    _zoomToTargetCardMark(location, companyName) {
        addresesData.forEach((company) => {
            if (company.company === companyName) {
                company.addresses.forEach((city) => {
                    if (city.address === location) {
                        this._zoomMap(city.coordOfAddress, this.maxZoom);
                        this._openCard(city.coordOfAddress);
                    } else {
                        if (city.city === location) {
                            this._zoomMap(city.coordOfAddress, 12);
                            this._openCard(city.coordOfAddress);
                        }
                    }
                });
            }
        });
    }

    _zoomMap(coords, zoomValue) {
        this.instance.setCenter(coords, zoomValue, {
            checkZoomRange: true,
            duration: 2,
        });
    }

    _getTargetCardMark = (elem) => {
        if (elem.target.classList.contains('contacts__item-button')) {
            // Находим родительский элемент кнопки
            const parent = elem.target.closest('.contacts__item');

            // Если родительский элемент найден
            if (parent) {
                // Находим элементы с классами "contacts__item-name", "contacts__item-address" и "js-map-city" внутри родительского элемента
                const companyName = parent.querySelector('.contacts__item-name')?.innerHTML || ''; // название компании
                const addressElement = parent.querySelector('.js-map-address')?.innerHTML || ''; // точный адрес
                const cityName = parent.querySelector('.js-map-city')?.innerHTML || ''; // название города
                // Дальше можно выполнять необходимые действия с найденными элементами
                if (addressElement) {
                    // Если точный адрес есть, то приближаем его
                    // В примере просто выведем текст элемента в консоль
                    this._zoomToTargetCardMark(addressElement, companyName);
                } else {
                    // Если нет, то приближаем город
                    this._zoomToTargetCardMark(cityName, companyName);
                }
            }
        }

        if (window.matchMedia('(max-width:576px)').matches) {
            contactsMapPopup.close();
        }
    };

    _setCompanies(select) {
        //фильтрация по городам в попапе
        const cityAddresses = document.querySelectorAll('.js-map-city');
        const selectedCity = select?.toLocaleLowerCase().trim();

        cityAddresses.forEach((city) => {
            const cityValue = city.innerHTML.toLocaleLowerCase().trim();
            const contact = city.closest('.js-map-search-contact');

            if (cityValue === selectedCity) {
                contact?.classList.remove('is-hide');
            } else {
                contact?.classList.add('is-hide');
            }
        });
    }

    _getTargetCity = (city) => {
        // поиск города в списке попапа
        let targetCity;
        if (city.target.querySelector('.js-collection-city')) {
            const target = city.target.querySelector('.js-collection-city');
            targetCity = target.innerHTML;
        } else {
            targetCity = city.target.innerHTML;
        }
        // поиск на карте
        selectedCity.innerHTML = targetCity;
        this._setCompanies(targetCity); // фильтруем города в попапе.
        this._findCity(targetCity); // ищем через api яндекса нужный город, и зумим туда.
    };

    _findCity(city) {
        ymaps
            .geocode(city, {
                results: 1,
            })
            .then((res) => {
                // Выбираем первый результат геокодирования.
                var firstGeoObject = res.geoObjects.get(0),
                    // Координаты геообъекта.
                    coords = firstGeoObject.geometry.getCoordinates(),
                    // Область видимости геообъекта.
                    bounds = firstGeoObject.properties.get('boundedBy');

                // Получаем строку с адресом и выводим в иконке геообъекта.
                firstGeoObject.properties.set('iconCaption', firstGeoObject.getAddressLine());

                // Добавляем первый найденный геообъект на карту.
                // Масштабируем карту на область видимости геообъекта.

                this.instance.setBounds(bounds, {
                    // Проверяем наличие тайлов на данном масштабе.
                    checkZoomRange: true,
                });

                if (typeof city === string) {
                    this.instance.setBounds(bounds, {
                        // Проверяем наличие тайлов на данном масштабе.
                        checkZoomRange: true,
                    });
                    const cityName = firstGeoObject.properties._data.name;
                    getPoints(cityName);

                    centerOfMap = firstGeoObject.properties._data.boundedBy[0];
                    this.instance.setCenter(centerOfMap, 10, {
                        checkZoomRange: true,
                        duration: 1,
                    });
                }
            });
    }

    render() {
        return html` <div class="map-container">
            <div class="map">${this._renderMap()}</div>
        </div>`;
    }
}
