interface IPageHandler {
    element: Element | Window;
    callback: (event: Event) => void;
    eventName?: string;
}

const pageHandlers: IPageHandler[] = [];

function init(container: HTMLElement | Document = document) {
    const dropdownCitiesButtons = container.querySelectorAll('.js-cities-button');

    dropdownCitiesButtons.forEach((item) => {
        const handleButtonClick = (event: Event) => {
            event.stopPropagation();

            item?.classList.toggle('collections__button--active');
        };

        if (item) {
            item.addEventListener('click', handleButtonClick);

            pageHandlers.push({
                callback: handleButtonClick,
                element: item,
            });
        }

        const handleDocumentClick = () => {
            item?.classList.remove('collections__button--active');
        };

        document.documentElement.addEventListener('click', handleDocumentClick);

        pageHandlers.push({
            callback: handleDocumentClick,
            element: document.documentElement,
        });

        const handleKeydown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                item?.classList.remove('collections__button--active');
            }
        };

        window.addEventListener('keydown', handleKeydown);

        pageHandlers.push({
            callback: handleKeydown as (event: Event) => void,
            element: window,
            eventName: 'keydown',
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    pageHandlers.forEach(({ callback, element, eventName }) => {
        element.removeEventListener(eventName ?? 'click', callback);
    });
}

const _module = { init, destroy };

export default _module;
