const selector = '[data-reveal], [data-reveal-fade], .wysiwyg > *';
let rootMargin = window.matchMedia('(min-width: 1024px)').matches ? '0px 0px -140px 0px' : '0px 0px -40px 0px';

const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            obs.unobserve(entry.target);
            entry.target.classList.add('is-revealed');
        }
    });
},
{ rootMargin: rootMargin });

function onResize() {
    rootMargin = window.matchMedia('(min-width: 1024px)').matches ? '0px 0px -160px 0px' : '0px 0px -40px 0px';
}

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.observe(el);
    });
    window.addEventListener('resize', onResize);
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.unobserve(el);
    });
    window.removeEventListener('resize', onResize);
}

function reinit(container: Element | Document = document) {
    destroy(container);

    setTimeout(() => {
        const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
        elements.forEach((el) => {
            el.classList.remove('is-revealed');
        });
    }, 1);

    setTimeout(() => {
        init(container);
    }, 2);
}

const _module = { init, destroy, reinit };

export default _module;
