interface IPageHandler {
    element: Element | Window;
    callback: (event: Event) => void;
    eventName?: string;
}

const pageHandlers: IPageHandler[] = [];

function handlePopupOpen(popupElement: HTMLElement | undefined) {
    popupElement?.classList.add('collections-popup--opened');

    document.body.classList.add('no-scroll');
    document.body.style.overflow = 'hidden';
}

function handlePopupClose(popupElement: HTMLElement | undefined) {
    popupElement?.classList.remove('collections-popup--opened');

    document.body.classList.remove('no-scroll');
    document.body.style.overflow = '';
}

function init(container: HTMLElement | Document = document) {
    const moreButtons = Array.from(container.querySelectorAll<HTMLButtonElement>('.js-collections-more-button'));
    const popups = Array.from(container.querySelectorAll<HTMLElement>('.js-collections-popup'));

    moreButtons.forEach((moreButton) => {
        const popupId = moreButton.dataset.popupId;
        const popup = popups.find((popup) => popup.dataset.id === popupId);

        const handleMorePopupClick = () => {
            handlePopupOpen(popup);
        };

        moreButton.addEventListener('click', handleMorePopupClick);

        pageHandlers.push({
            callback: handleMorePopupClick as (event: Event) => void,
            element: moreButton,
        });
    });

    popups.forEach((popup) => {
        const closeButton = popup.querySelector('.js-collections-popup-close');

        const handleCloseButtonClick = () => {
            handlePopupClose(popup);
        };

        if (closeButton) {
            closeButton.addEventListener('click', handleCloseButtonClick);

            pageHandlers.push({
                callback: handleCloseButtonClick,
                element: closeButton,
            });
        }
    });

    const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            popups.forEach((popup) => {
                handlePopupClose(popup);
            });
        }
    };

    window.addEventListener('keydown', handleKeydown);

    pageHandlers.push({
        callback: handleKeydown as (event: Event) => void,
        element: window,
        eventName: 'keydown',
    });
}

function destroy(container: HTMLElement | Document = document) {
    pageHandlers.forEach(({ callback, element, eventName }) => {
        element.removeEventListener(eventName ?? 'click', callback);
    });
}

const _module = { init, destroy };

export default _module;
