import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
import revealAnimations from './reveal';

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <button class="button button--round btn-slider btn-slider--prev js-text-slider-prev" aria-label="previous slide">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.25 13.25L16.9622 7.14799C17.0495 7.06864 17.0495 6.93137 16.9622 6.85201L10.25 0.75" stroke="#3C3C3C" stroke-width="1.2" stroke-linecap="round"/>
                <path d="M17.125 7L1.5 7" stroke="#3C3C3C" stroke-width="1.2" stroke-linecap="round"/>
            </svg>
        </button>
        <button class="button button--round btn-slider js-text-slider-next" aria-label="next slide">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.25 13.25L16.9622 7.14799C17.0495 7.06864 17.0495 6.93137 16.9622 6.85201L10.25 0.75" stroke="#3C3C3C" stroke-width="1.2" stroke-linecap="round"/>
                <path d="M17.125 7L1.5 7" stroke="#3C3C3C" stroke-width="1.2" stroke-linecap="round"/>
            </svg>
        </button>
    `;
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-text-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper, Navigation, Lazy} = await fetchSwiper();
            const navigation = el.querySelector<HTMLElement>('.js-text-slider-ctrls');
            if (navigation) createNavigation(navigation);

            executeOnIntersection(
                el,
                () => {
                    new Swiper(el, {
                        modules: [Navigation, Lazy],
                        slidesPerView: 1,
                        speed: 800,
                        loop: true,
                        spaceBetween: 10,
                        centeredSlides: true,
                        navigation: {
                            prevEl: '.js-text-slider-prev',
                            nextEl: '.js-text-slider-next'
                        },
                        loopAdditionalSlides: 2,
                        on: {
                            init: () => {
                                revealAnimations.init();
                            },
                        }
                    });
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}

const _module = { init };

export default _module;
