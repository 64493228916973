const addresesData = [
    {
        company: 'Салана Трейд Крым',
        website: 'salana-trade.ru',
        legalAddress: 'Камышовое ш., 15, Севастополь',
        addresses: [
            {
                city: 'Севастополь',
                address: 'г. Севастополь, Ул. Павла Силаева 8а, рынок «Силаевский» маг. «Семеновна».',
                phone: '+79788190830',
                district: [44.571287, 33.515002],
                coordOfAddress: [44.574222, 33.519108],
            },
            {
                city: 'Севастополь',
                address: 'г. Севастополь, Ул. Адмирала Октябрьского 20а, рынок «Чайка» маг. «Семеновна»',
                phone: '+79788190830',
                district: [44.599617, 33.511364],
                coordOfAddress: [44.60255, 33.51547],
            },
            {
                city: 'Севастополь',
                address: 'г. Севастополь, ПОР 61, Тц «Адрия», маг. «Семеновна»',
                phone: '+79788190830',
                district: [44.587107, 33.45778],
                coordOfAddress: [44.590041, 33.461885],
            },
            {
                city: 'Севастополь',
                address: 'г. Севастополь, 5км, рынок Севпривоз, маг. Агромаркет',
                phone: '+79780940826',
                district: [44.53089, 33.517877],
                coordOfAddress: [44.54602, 33.531873],
            },
            {
                city: 'Симферополь',
                address: 'г. Симферополь, Проспект Кирова 70/2, маг. Фермер',
                phone: '+79788190830',
                district: [44.953596, 34.101638],
                coordOfAddress: [44.956511, 34.105744],
            },
            {
                city: 'Керчь',
                address: 'г. Керчь, ул. Карла Маркса 11а, маг. Агромаркет ',
                phone: '+79780024016',
                district: [45.35601, 36.465222],
                coordOfAddress: [45.358904, 36.469328],
            },
            {
                city: 'Керчь',
                address: 'г. Керчь, ул. Козлова 25, маг. Цветочный Оазис',
                phone: '+79788180064',
                district: [45.356758, 36.466696],
                coordOfAddress: [45.35967, 36.47081],
            },
        ],
    },
    {
        company: 'Сима-Ленд ТД ООО',
        website: 'sima-land.ru',
        legalAddress: '620010, г. Екатеринбург ул. Черняховского, 86',
        addresses: [
            {
                city: 'Екатеринбург',
                address: 'г. Екатеринбург, ул. Черняховского, 86/12',
                phone: '+7 800 234 1000 , 8 (343) 278-67-00',
                district: [56.758842, 60.745463],
                coordOfAddress: [56.761097, 60.749568],
            },
            {
                city: 'Екатеринбург',
                address: 'г. Екатеринбург, Veer Mall (Проспект Космонавтов 108Д)',
                district: [56.915564, 60.612342],
                coordOfAddress: [56.918969, 60.614704],
            },
            {
                city: 'Ревда',
                address: 'г. Ревда, ул. Российская 21, ТЦ Дом',
                district: [56.789579, 59.908161],
                coordOfAddress: [56.791832, 59.912267],
            },
            {
                city: 'Новосибирск',
                address: 'г. Новосибирск, пл. Карла Маркса, 2, блок Б, ТЦ Галерея Фестиваль',
                phone: '+7 383 230 2393',
                district: [54.981319, 82.888494],
                coordOfAddress: [54.98368, 82.8926],
            },
            {
                city: 'Санкт-Петербург',
                address: 'г. Санкт-Петербург, ул. Будапештская, 11, литер А, 10 Н ТК Торговый город',
                phone: '+7 812 201 0113',
                district: [59.867005, 30.364584],
                coordOfAddress: [59.86907, 30.368689],
            },
            {
                city: 'Нижний Новгород',
                address: 'г. Нижний Новгород, Гагарина просп., д. 39, ТЦ Швейцария',
                phone: '+7 831 200 0108',
                district: [56.250082, 43.963487],
                coordOfAddress: [56.252367, 43.967592],
            },
            {
                city: 'Екатеринбург',
                address: 'г. Екатеринбург, ул. Черняховского, 86, кор. 9',
                phone: '+7 800 234 1000 , 8 (343) 278-67-00',
                district: [56.755876, 60.751562],
                coordOfAddress: [56.758132, 60.755668],
            },
            {
                city: 'Москва и МО',
                address: 'Москва и МО, Раменский городской округ, рабочий посёлок Быково, Аэропортовская улица, 14кТ',
                phone: '+7 (495) 049-29-99, +7 800 234 1000',
                district: [55.621368, 38.056148],
                coordOfAddress: [55.623691, 38.060253],
            },
            {
                city: 'Краснодар',
                address: 'г. Краснодар, ул. Ростовское шоссе 14/4',
                phone: '+7 (912) 224-62-34',
                district: [45.146789, 39.052146],
                coordOfAddress: [45.149694, 39.056251],
            },
            {
                city: 'Новосибирск',
                address: 'г. Новосибирск, ул. Планетная ул, дом 32/1',
                district: [55.053253, 82.947783],
                coordOfAddress: [55.055609, 82.951889],
            },
            {
                city: 'Санкт-Петербург',
                address: "г. Санкт-Петербург, пр-кт Индустриальный, дом 70Б, Территория БЦ 'БРОНКОС'",
                phone: '+7 (919) 360-41-99',
                district: [59.968601, 30.45137],
                coordOfAddress: [59.970659, 30.455475],
            },
            {
                city: 'Иркутск',
                address: 'г. Иркутск, ул. Розы Люксембург 198В/1',
                district: [52.361136, 104.146452],
                coordOfAddress: [52.363649, 104.150557],
            },
            {
                city: 'Хабаровск',
                address: 'г. Хабаровск, ул. Строительная, дом № 24, корпус У',
                phone: '+7 800 234 1000',
                district: [48.373552, 135.097941],
                coordOfAddress: [48.376287, 135.102046],
            },
        ],
    },
    {
        company: 'Хит Сад (ГК Зеленый Дом)',
        website: 'garden-zoo.ru',
        legalAddress: '440018, г. Пенза, ул. Суворова, д. 225, пом. 10А',
        addresses: [
            {
                city: 'Барнаул',
                district: [53.186965, 83.533018],
                coordOfAddress: [53.346785, 83.776856],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Владимир',
                district: [56.051878, 40.164916],
                coordOfAddress: [56.129057, 40.406635],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Воронеж',
                district: [51.484978, 39.013096],
                coordOfAddress: [51.660781, 39.200296],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Екатеринбург',
                district: [56.593795, 60.006832],
                coordOfAddress: [56.838011, 60.597474],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Иркутск',
                district: [52.209243, 104.058956],
                coordOfAddress: [52.289588, 104.280606],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Казань',
                district: [55.60313, 48.82057],
                coordOfAddress: [55.796127, 49.106414],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Калининград',
                district: [54.630878, 20.293868],
                coordOfAddress: [54.710162, 20.510137],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Каменск',
                district: [51.972204, 106.568813],
                coordOfAddress: [51.983538, 106.583968],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Кемерово',
                district: [55.257335, 85.909134],
                coordOfAddress: [55.355198, 86.086847],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Краснодар',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.03547, 38.975313],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Кузнецк',
                district: [53.082586, 46.539568],
                coordOfAddress: [53.119335, 46.601174],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Москва',
                district: [55.142226, 36.803079],
                coordOfAddress: [55.753438, 37.612299],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Нижний Ломов',
                district: [53.488902, 43.635225],
                coordOfAddress: [53.530049, 43.672999],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Нижний Новгород',
                district: [56.185272, 43.547621],
                coordOfAddress: [56.326797, 44.006516],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Новороссийск',
                district: [44.65864, 37.629933],
                coordOfAddress: [44.723771, 37.768813],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Новосибирск',
                district: [54.803637, 82.751897],
                coordOfAddress: [55.030204, 82.92043],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Пенза',
                district: [53.098945, 44.829786],
                coordOfAddress: [53.195042, 45.018316],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Пермь',
                district: [57.867842, 55.802366],
                coordOfAddress: [58.010455, 56.229443],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Петропавловск-Камчатский',
                district: [52.886333, 158.521449],
                coordOfAddress: [53.024265, 158.643503],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Ростов-на-Дону',
                district: [47.153367, 39.404735],
                coordOfAddress: [47.222078, 39.720358],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Самара',
                district: [53.09185, 49.732198],
                coordOfAddress: [53.195878, 50.100202],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Санкт-Петербург',
                district: [59.633711, 29.425611],
                coordOfAddress: [59.938784, 30.314997],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Саранск',
                district: [54.149224, 45.054725],
                coordOfAddress: [54.187433, 45.183938],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Саратов',
                district: [51.392303, 45.80542],
                coordOfAddress: [51.533562, 46.034266],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Симферополь',
                district: [44.89091, 34.028372],
                coordOfAddress: [44.948237, 34.100327],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Сочи',
                district: [43.395071, 39.149685],
                coordOfAddress: [43.585472, 39.723098],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Сызрань',
                district: [52.977382, 48.293663],
                coordOfAddress: [53.155669, 48.474611],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Тамбов',
                district: [52.670654, 41.272871],
                coordOfAddress: [52.721295, 41.45275],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Тюмень',
                district: [57.001471, 65.276844],
                coordOfAddress: [57.152985, 65.541227],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Ульяновск',
                district: [54.191452, 48.237483],
                coordOfAddress: [54.314192, 48.403132],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Уфа',
                district: [54.500719, 55.778561],
                coordOfAddress: [54.735152, 55.958736],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Челябинск',
                district: [54.986227, 61.143596],
                coordOfAddress: [55.159902, 61.402554],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Чита',
                district: [51.963859, 113.133539],
                coordOfAddress: [52.033635, 113.501049],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Якутск',
                district: [61.909348, 129.523724],
                coordOfAddress: [62.027221, 129.732178],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
            {
                city: 'Ярославль',
                district: [57.525615, 39.703721],
                coordOfAddress: [57.626559, 39.893813],
                info: 'Товар не продаётся в гипермаркетах, закажите его онлайн c возможностью самовывоза.',
            },
        ],
    },
    {
        company: "ООО 'КОНВЕНТ'",
        website: 'tdconvent.ru',
        addresses: [
            {
                city: 'Дзержинский',
                address: 'Московская обл. г. Дзержинский, Северный проезд, дом 2',
                district: [55.641376, 37.838297],
                coordOfAddress: [55.643698, 37.842403],
            },
        ],
    },
    {
        company: 'СадХозТорг ООО, УралХозТорг ТД ООО',
        website: 'sadhoztorg.ru',
        addresses: [
            {
                city: 'Екатеринбург',
                address: 'г. Екатеринбург, ул. Лукиных, 1В',
                district: [56.880499, 60.568791],
                coordOfAddress: [56.882747, 60.572897],
            },
        ],
    },
    // {
    //     company: 'ОБИ',
    //     website: 'obi.ru',
    //     addresses: [
    //         {
    //             city: 'Брянск',
    //             district: [53.183841, 34.191146],
    //             coordOfAddress: [53.2434, 34.363991],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Волгоград',
    //             district: [48.406955, 44.108861],
    //             coordOfAddress: [48.707067, 44.516975],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Екатеринбург',
    //             district: [56.593795, 60.006832],
    //             coordOfAddress: [56.838011, 60.597474],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Казань',
    //             district: [55.60313, 48.82057],
    //             coordOfAddress: [55.796127, 49.106414],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Краснодар',
    //             district: [44.978295, 38.785059],
    //             coordOfAddress: [45.03547, 38.975313],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Москва',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.755773, 37.616913],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Новгород',
    //             district: [56.185272, 43.547621],
    //             coordOfAddress: [56.326797, 44.006516],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Омск',
    //             district: [54.824347, 73.097604],
    //             coordOfAddress: [54.989347, 73.368221],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Рязань',
    //             district: [54.528253, 39.536949],
    //             coordOfAddress: [54.629221, 39.737111],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Саратов',
    //             district: [51.392303, 45.80542],
    //             coordOfAddress: [51.533562, 46.034266],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Сургут',
    //             district: [61.222557, 73.21972],
    //             coordOfAddress: [61.241778, 73.393032],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тула',
    //             district: [54.062137, 37.498716],
    //             coordOfAddress: [54.193122, 37.617348],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    {
        company: 'ООО «Бауцентр Рус»',
        website: 'baucenter.ru',
        addresses: [
            {
                city: 'Москва и МО',
                district: [55.142226, 36.803079],
                coordOfAddress: [55.752507, 37.62315],
                info: 'Информация о точках продаж представлена на сайте.',
            },
            {
                city: 'Калининград',
                address: 'г. Калининград, улица Дзержинского, 35',
                district: [54.630878, 20.293868],
                coordOfAddress: [54.695799, 20.525866],
            },
            {
                city: 'Краснодар',
                address: 'Ростовское шоссе, 28/7',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.096295, 38.993472],
            },
            {
                city: 'Краснодар',
                address: 'ул. Селезнёва, 4, Краснодар',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.027480, 39.021510],
            },
            {
                city: 'Омск',
                address: 'улица 10 лет Октября, 190',
                district: [54.824347, 73.097604],
                coordOfAddress: [54.992456, 73.488397],
            },
            {
                city: 'Новороссийск',
                address: 'улица Золотая Рыбка, 23',
                district: [54.803637, 82.751897],
                coordOfAddress: [44.775178, 37.694507],
            },
        ],
    },
    // {
    //     company: 'Стройцентр ООО (ТЦ Уют Строй)',
    //     website: 'uyutstroy.su',
    //     addresses: [
    //         {
    //             city: 'Симферополь',
    //             address: 'Республика Крым, г. Симферополь, пр. Победы, 245',
    //             district: [44.979111, 34.146114],
    //             coordOfAddress: [44.982025, 34.150219],
    //         },
    //     ],
    // },
    {
        company: 'Радуга',
        website: 'radugakursk.ru',
        addresses: [
            {
                city: 'Курск',
                district: [51.620716, 36.059912],
                coordOfAddress: [51.730846, 36.193015],
                info: 'Информация о точках продаж представлена на сайте.',
            },
            {
                city: 'Курчатов',
                district: [50.735619, 78.488016],
                coordOfAddress: [50.756325, 78.548329],
                info: 'Информация о точках продаж представлена на сайте.',
            },
            {
                city: 'Железногорск',
                district: [52.261942, 35.32076],
                coordOfAddress: [52.339203, 35.350871],
                info: 'Информация о точках продаж представлена на сайте.',
            },
        ],
    },
    {
        company: 'Фирма Эрнис',
        website: 'diy.by',
        addresses: [
            {
                city: 'Минск',
                address: 'Раковская ул., 17А',
                district: [53.901886, 27.545149],
                coordOfAddress: [53.90431, 27.549255],
            },
            {
                city: 'Минск',
                address: 'ул. Притыцкого, 101',
                district: [53.903143, 27.42977],
                coordOfAddress: [53.905567, 27.433875],
            },
        ],
    },
    {
        company: "Общество с ограниченной ответственностью 'Астомстрой'",
        website: 'mile.by',
        addresses: [
            {
                city: 'Минск',
                address: 'ул. Мележа, д. 5, корп. 2, пом. 1301',
                district: [53.937435, 27.593371],
                coordOfAddress: [53.939858, 27.597476],
            },
            {
                city: 'Гродно',
                district: [53.599048, 23.709406],
                coordOfAddress: [53.677839, 23.829529],
                info: 'Информация о точках продаж представлена на сайте.',
            },
            {
                city: 'Брест',
                district: [52.023911, 23.566017],
                coordOfAddress: [52.093754, 23.685107],
                info: 'Информация о точках продаж представлена на сайте.',
            },
            {
                city: 'Молодечно',
                district: [54.278619, 26.78866],
                coordOfAddress: [54.307332, 26.838939],
                info: 'Информация о точках продаж представлена на сайте.',
            },
        ],
    },
    // {
    //     company: 'Hoff (Домашний интерьер ООО)',
    //     website: 'hoff.ru',
    //     addresses: [
    //         {
    //             city: 'Москва',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.756305, 37.61865],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Белгород',
    //             district: [50.505075, 36.444642],
    //             coordOfAddress: [50.595414, 36.587277],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Волгоград',
    //             district: [48.406955, 44.108861],
    //             coordOfAddress: [48.707067, 44.516975],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Воронеж',
    //             district: [51.484978, 39.013096],
    //             coordOfAddress: [51.660781, 39.200296],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Екатеринбург',
    //             district: [56.593795, 60.006832],
    //             coordOfAddress: [56.838011, 60.597474],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ижевск',
    //             district: [56.716478, 53.007609],
    //             coordOfAddress: [56.852676, 53.2069],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Казань',
    //             district: [55.60313, 48.82057],
    //             coordOfAddress: [55.796127, 49.106414],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Краснодар',
    //             district: [44.978295, 38.785059],
    //             coordOfAddress: [45.03547, 38.975313],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Липецк',
    //             district: [52.491026, 39.379923],
    //             coordOfAddress: [52.608826, 39.599229],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Новгород',
    //             district: [56.185272, 43.547621],
    //             coordOfAddress: [56.326797, 44.006516],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Новороссийск',
    //             district: [44.65864, 37.629933],
    //             coordOfAddress: [44.723771, 37.768813],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Подольск',
    //             district: [55.298071, 37.470985],
    //             coordOfAddress: [55.431136, 37.544997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Пушкино',
    //             district: [55.962931, 37.780491],
    //             coordOfAddress: [56.011318, 37.847164],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Реутов',
    //             district: [55.744536, 37.842573],
    //             coordOfAddress: [55.760515, 37.85515],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ростов на Дону',
    //             district: [47.153367, 39.404735],
    //             coordOfAddress: [47.222078, 39.720358],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Самара',
    //             district: [53.09185, 49.732198],
    //             coordOfAddress: [53.195878, 50.100202],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Сочи',
    //             district: [43.395071, 39.149685],
    //             coordOfAddress: [43.585472, 39.723098],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Саврополье',
    //             district: [43.658472, 40.846405],
    //             coordOfAddress: [44.953551, 43.344521],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Сургут',
    //             district: [61.222557, 73.21972],
    //             coordOfAddress: [61.241778, 73.393032],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тула',
    //             district: [54.062137, 37.498716],
    //             coordOfAddress: [54.193122, 37.617348],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тюмень',
    //             district: [57.001471, 65.276844],
    //             coordOfAddress: [57.152985, 65.541227],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Химки',
    //             district: [55.877405, 37.228916],
    //             coordOfAddress: [55.889361, 37.444853],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'АГАВА ООО (ТС Мегастрой)',
    //     website: 'www.megastroy.com',
    //     addresses: [
    //         {
    //             city: 'Казань',
    //             district: [55.60313, 48.82057],
    //             coordOfAddress: [55.796127, 49.106414],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Набережные Челны',
    //             district: [55.635569, 52.18344],
    //             coordOfAddress: [55.741272, 52.403662],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ульяновск',
    //             district: [54.191452, 48.237483],
    //             coordOfAddress: [54.314192, 48.403132],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Чебоксары',
    //             district: [56.0513, 47.096245],
    //             coordOfAddress: [56.139918, 47.247728],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Йошкар-Ола',
    //             district: [56.58926, 47.808798],
    //             coordOfAddress: [56.6316, 47.886178],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Стерлитамак',
    //             district: [53.576829, 55.871222],
    //             coordOfAddress: [53.630403, 55.930825],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Саранск',
    //             district: [54.149224, 45.054725],
    //             coordOfAddress: [54.187433, 45.183938],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'Азбука вкуса (ООО Городской супермаркет)',
    //     website: 'av.ru',
    //     addresses: [
    //         {
    //             city: 'Москва и МО',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.75321, 37.619055],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'Касторама Рус ООО',
    //     website: 'castorama.ru',
    //     addresses: [
    //         {
    //             city: 'Воронеж',
    //             district: [51.484978, 39.013096],
    //             coordOfAddress: [51.660781, 39.200296],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Екатеринбург',
    //             district: [56.593795, 60.006832],
    //             coordOfAddress: [56.838011, 60.597474],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Краснодар',
    //             district: [44.978295, 38.785059],
    //             coordOfAddress: [45.03547, 38.975313],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Новгород',
    //             district: [56.185272, 43.547621],
    //             coordOfAddress: [56.326797, 44.006516],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Оренбург',
    //             district: [51.683044, 54.988358],
    //             coordOfAddress: [51.768205, 55.097],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Пермь',
    //             district: [57.867842, 55.802366],
    //             coordOfAddress: [58.010455, 56.229443],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ростов на Дону',
    //             district: [47.153367, 39.404735],
    //             coordOfAddress: [47.222078, 39.720358],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Саратов',
    //             district: [51.392303, 45.80542],
    //             coordOfAddress: [51.533562, 46.034266],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Челябинск',
    //             district: [54.986227, 61.143596],
    //             coordOfAddress: [55.159902, 61.402554],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Щелково',
    //             district: [55.865561, 37.938873],
    //             coordOfAddress: [55.92022, 37.991532],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Щербинка',
    //             district: [55.487096, 37.505058],
    //             coordOfAddress: [55.501315, 37.564554],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'Лента ООО',
    //     website: 'lenta.ru',
    //     addresses: [
    //         {
    //             city: 'Ленинградская область',
    //             district: [58.417086, 26.580242],
    //             coordOfAddress: [59.337017, 29.608975],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Москва и МО',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.75511, 37.622396],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Кемеровская область',
    //             district: [52.162382, 84.452408],
    //             coordOfAddress: [54.897945, 86.990391],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Новосибирская область',
    //             district: [53.290917, 75.087318],
    //             coordOfAddress: [55.582396, 79.26487],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Челябинская область',
    //             district: [51.990848, 57.131388],
    //             coordOfAddress: [54.446204, 60.39565],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ростовская область',
    //             district: [45.951826, 38.220306],
    //             coordOfAddress: [47.728738, 41.268137],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Омская область',
    //             district: [53.435147, 70.357401],
    //             coordOfAddress: [56.103477, 73.344425],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Краснодарский край',
    //             district: [43.250265, 36.521179],
    //             coordOfAddress: [45.54491, 39.610422],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Волгоградская область',
    //             district: [47.443746, 41.168343],
    //             coordOfAddress: [49.615821, 44.151415],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Самарская область',
    //             district: [51.772998, 47.930214],
    //             coordOfAddress: [53.452932, 50.34431],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Свердловская область',
    //             district: [56.053934, 57.228325],
    //             coordOfAddress: [58.58676, 61.530761],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Вологодская область',
    //             district: [58.47755, 34.718107],
    //             coordOfAddress: [59.795958, 40.669185],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'МАКСИДОМ ООО (ТС МАКСИДОМ)',
    //     website: 'maxidom.ru',
    //     addresses: [
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Москва и МО',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.75094, 37.617078],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Новгород',
    //             district: [56.185272, 43.547621],
    //             coordOfAddress: [56.326797, 44.006516],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Казань',
    //             district: [55.60313, 48.82057],
    //             coordOfAddress: [55.796127, 49.106414],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Самара',
    //             district: [53.09185, 49.732198],
    //             coordOfAddress: [53.195878, 50.100202],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Екатеринбург',
    //             district: [56.593795, 60.006832],
    //             coordOfAddress: [56.838011, 60.597474],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Уфа',
    //             district: [54.500719, 55.778561],
    //             coordOfAddress: [54.735152, 55.958736],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Воронеж',
    //             district: [51.484978, 39.013096],
    //             coordOfAddress: [51.660781, 39.200296],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'ООО УК Старт (сеть Домовой)',
    //     website: 'tddomovoy.ru',
    //     addresses: [
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Москва и МО',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.755799, 37.623178],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тольятти',
    //             district: [53.446363, 49.192796],
    //             coordOfAddress: [53.507852, 49.420411],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Самара',
    //             district: [53.09185, 49.732198],
    //             coordOfAddress: [53.195878, 50.100202],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Воронеж',
    //             district: [51.484978, 39.013096],
    //             coordOfAddress: [51.660781, 39.200296],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Уфа',
    //             district: [54.500719, 55.778561],
    //             coordOfAddress: [54.735152, 55.958736],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тула',
    //             district: [54.062137, 37.498716],
    //             coordOfAddress: [54.193122, 37.617348],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Брянск',
    //             district: [53.183841, 34.191146],
    //             coordOfAddress: [53.2434, 34.363991],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Магнитогорск',
    //             district: [53.300045, 58.897431],
    //             coordOfAddress: [53.407163, 58.980291],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Липецк',
    //             district: [52.491026, 39.379923],
    //             coordOfAddress: [52.608826, 39.599229],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Новгород',
    //             district: [56.185272, 43.547621],
    //             coordOfAddress: [56.326797, 44.006516],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'АШАН ООО',
    //     website: 'auchan.ru',
    //     addresses: [
    //         {
    //             city: 'Москва',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.752313, 37.625936],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Казань',
    //             district: [55.60313, 48.82057],
    //             coordOfAddress: [55.796127, 49.106414],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Уфа',
    //             district: [54.500719, 55.778561],
    //             coordOfAddress: [54.735152, 55.958736],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Самара',
    //             district: [53.09185, 49.732198],
    //             coordOfAddress: [53.195878, 50.100202],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Воронеж',
    //             district: [51.484978, 39.013096],
    //             coordOfAddress: [51.660781, 39.200296],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Волгоград',
    //             district: [48.406955, 44.108861],
    //             coordOfAddress: [48.707067, 44.516975],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    // {
    //     company: 'Леруа Мерлен',
    //     website: 'leroymerlin.ru',
    //     addresses: [
    //         {
    //             city: 'Барнаул',
    //             district: [53.186965, 83.533018],
    //             coordOfAddress: [53.346785, 83.776856],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Владимир',
    //             district: [56.051878, 40.164916],
    //             coordOfAddress: [56.129057, 40.406635],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Воронеж',
    //             district: [51.484978, 39.013096],
    //             coordOfAddress: [51.660781, 39.200296],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Екатеринбург',
    //             district: [56.593795, 60.006832],
    //             coordOfAddress: [56.838011, 60.597474],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Иркутск',
    //             district: [52.209243, 104.058956],
    //             coordOfAddress: [52.289588, 104.280606],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Казань',
    //             district: [55.60313, 48.82057],
    //             coordOfAddress: [55.796127, 49.106414],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Калининград',
    //             district: [54.630878, 20.293868],
    //             coordOfAddress: [54.710162, 20.510137],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Каменск',
    //             district: [51.972204, 106.568813],
    //             coordOfAddress: [51.983538, 106.583968],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Кемерово',
    //             district: [55.257335, 85.909134],
    //             coordOfAddress: [55.355198, 86.086847],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Краснодар',
    //             district: [44.978295, 38.785059],
    //             coordOfAddress: [45.03547, 38.975313],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Кузнецк',
    //             district: [53.082586, 46.539568],
    //             coordOfAddress: [53.119335, 46.601174],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Москва',
    //             district: [55.142226, 36.803079],
    //             coordOfAddress: [55.755864, 37.617698], //////
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Ломов',
    //             district: [53.488902, 43.635225],
    //             coordOfAddress: [53.530049, 43.672999],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Нижний Новгород',
    //             district: [56.185272, 43.547621],
    //             coordOfAddress: [56.326797, 44.006516],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Новороссийск',
    //             district: [44.65864, 37.629933],
    //             coordOfAddress: [44.723771, 37.768813],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Новосибирск',
    //             district: [54.803637, 82.751897],
    //             coordOfAddress: [55.030204, 82.92043],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Пенза',
    //             district: [53.098945, 44.829786],
    //             coordOfAddress: [53.195042, 45.018316],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Пермь',
    //             district: [57.867842, 55.802366],
    //             coordOfAddress: [58.010455, 56.229443],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Петропавловск-Камчатский',
    //             district: [52.886333, 158.521449],
    //             coordOfAddress: [53.024265, 158.643503],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ростов-на-Дону',
    //             district: [47.153367, 39.404735],
    //             coordOfAddress: [47.222078, 39.720358],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Самара',
    //             district: [53.09185, 49.732198],
    //             coordOfAddress: [53.195878, 50.100202],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Санкт-Петербург',
    //             district: [59.633711, 29.425611],
    //             coordOfAddress: [59.938784, 30.314997],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Саранск',
    //             district: [54.149224, 45.054725],
    //             coordOfAddress: [54.187433, 45.183938],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Саратов',
    //             district: [51.392303, 45.80542],
    //             coordOfAddress: [51.533562, 46.034266],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Симферополь',
    //             district: [44.89091, 34.028372],
    //             coordOfAddress: [44.948237, 34.100327],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Сочи',
    //             district: [43.395071, 39.149685],
    //             coordOfAddress: [43.585472, 39.723098],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Сызрань',
    //             district: [52.977382, 48.293663],
    //             coordOfAddress: [53.155669, 48.474611],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тамбов',
    //             district: [52.670654, 41.272871],
    //             coordOfAddress: [52.721295, 41.45275],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Тюмень',
    //             district: [57.001471, 65.276844],
    //             coordOfAddress: [57.152985, 65.541227],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ульяновск',
    //             district: [54.191452, 48.237483],
    //             coordOfAddress: [54.314192, 48.403132],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Уфа',
    //             district: [54.500719, 55.778561],
    //             coordOfAddress: [54.735152, 55.958736],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Челябинск',
    //             district: [54.986227, 61.143596],
    //             coordOfAddress: [55.159902, 61.402554],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Чита',
    //             district: [51.963859, 113.133539],
    //             coordOfAddress: [52.033635, 113.501049],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Якутск',
    //             district: [61.909348, 129.523724],
    //             coordOfAddress: [62.027221, 129.732178],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //         {
    //             city: 'Ярославль',
    //             district: [57.525615, 39.703721],
    //             coordOfAddress: [57.626559, 39.893813],
    //             info: 'Информация о точках продаж представлена на сайте.',
    //         },
    //     ],
    // },
    {
        company: 'ФлораТверь (ИП Федотов В.М.)',
        website: 'floratver.ru',
        addresses: [
            {
                city: 'Тверь',
                address: '28, деревня Большие Перемерки',
                district: [56.820572, 35.970502],
                coordOfAddress: [56.822823, 35.974608],
            },
        ],
    },
    {
        company: 'Sun Green (ИП Матакаева А.Р.)',
        website: 'sun-green.ru',
        addresses: [
            {
                city: 'Саратов',
                address: 'Усть-Курдюмская ул., 10',
                district: [51.565648, 46.063892],
                coordOfAddress: [51.568207, 46.067997],
            },
        ],
    },
    {
        company: 'Гарден Центр Групп ООО (Садовый центр ДАРВИН)',
        website: 'darvin-market.ru',
        addresses: [
            {
                city: 'Солнечногорск',
                address: 'г. Солнечногорск, ул. Красная, д. 194',
                district: [56.202096, 36.94778],
                coordOfAddress: [56.204384, 36.951885],
            },
        ],
    },
    {
        company: 'Садовые центры Летто',
        website: 'letto.ru',
        addresses: [
            {
                city: 'Краснодар',
                address: 'улица имени М.Е. Соколова, 66/1',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.110061, 38.984547],
            },
            {
                city: 'Краснодар',
                address: 'Уральская улица, 214А, Краснодар',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.043112, 39.122513],
            },
            {
                city: 'Краснодар',
                address: 'Снежная улица, 1/3',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.102486, 38.917654],
            },
            {
                city: 'Краснодар',
                address: 'Восточная улица, 1, жилой массив Пашковский, Краснодар',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.040024, 39.120445],
            },
            {
                city: 'Краснодар',
                address: 'Восточный обход, 239сА',
                district: [44.978295, 38.785059],
                coordOfAddress: [45.102820, 39.123947],
            },
        ],
    },
    {
        company: 'Дольче Вита ООО',
        website: 'flower-lipetsk.ru',
        addresses: [
            {
                city: 'Липецк',
                address: 'Липецкая область, г.о. Город Липецк, ул. 50 Лет НЛМК, д. 18',
                district: [52.582815, 39.579412],
                coordOfAddress: [52.585315, 39.583517],
            },
        ],
    },
    {
        company: 'ИП Беликова В.В. (ФИТО-ЛАЙН, Цветы ТОРГ)',
        website: 'fito-line.ru',
        addresses: [
            {
                city: 'Чебоксары',
                address: 'Чувашия, Чувашская Республика, г.Чебоксары, проезд Машиностроителей, д.1 корпус 6',
                district: [56.123458, 47.306505],
                coordOfAddress: [56.125751, 47.31061],
            },
        ],
    },
    {
        company: 'Коломенский садовый центр ООО (СЦ СадОк)',
        website: 'садок.рф',
        addresses: [
            {
                city: 'Коломна',
                address: 'Московская Область, г. Коломна, ул. Октябрьская, д.12',
                district: [55.06238, 38.841248],
                coordOfAddress: [55.064736, 38.845354],
            },
        ],
    },
    {
        company: 'Садовый центр Лейка (ИП Мамаев Б.М.)',
        website: 'taplink.cc/leika.center',
        addresses: [
            {
                city: 'Махачкала',
                address: 'г. Махачкала, ул. Бейбулатова, 53',
                district: [42.96426, 47.526897],
                coordOfAddress: [42.967275, 47.531003],
            },
        ],
    },
    {
        company: 'Оазис Плюс ООО',
        website: 'kalinkovo.com',
        addresses: [
            {
                city: 'Калинково',
                address: 'Калининградская Область, р-н Гвардейский, п. Калинково, д.25а',
                district: [54.688576, 20.98319],
                coordOfAddress: [54.690954, 20.987295],
            },
        ],
    },
    {
        company: 'Сиб-флауэрс ООО (Гринвилль)',
        website: 'sibflowers.ru',
        addresses: [
            {
                city: 'Новосибирск',
                address: 'Новосибирская Область, г. Новосибирск, пр-д Северный, д. 9',
                district: [54.946638, 82.90859],
                coordOfAddress: [54.949001, 82.912695],
            },
        ],
    },
    {
        company: 'Цветочный мир ООО, садовый центр Саранск',
        website: 'flowers-sib.ru',
        addresses: [
            {
                city: 'Саранск',
                address: 'г. Саранск, Лямбирское ш., 10В, стр. 1',
                district: [54.228148, 45.150979],
                coordOfAddress: [54.230554, 45.155084],
            },
        ],
    },
    {
        company: 'Садовый центр, гипермаркет Хомяк',
        website: 'sadoviycentrtula.ru',
        addresses: [
            {
                city: 'Тула',
                address: 'г. Тула, Парашютный пр., 36',
                district: [54.195224, 37.537515],
                coordOfAddress: [54.197631, 37.54162],
            },
            {
                city: 'Тула',
                address: 'г.Тула, садовый Поселок Рассвет, территория Садовый центр, здание 1',
                district: [54.171408, 37.421506],
                coordOfAddress: [54.173817, 37.425611],
            },
        ],
    },
    {
        company: 'Садовый центр Сад.Ко™',
        website: 'sadkoltso.ru',
        addresses: [
            {
                city: 'Ростов-на-Дону',
                address: 'г. Ростов-на-Дону, ул. Портовая, д. 188г',
                district: [47.199717, 39.666423],
                coordOfAddress: [47.202515, 39.670528],
            },
        ],
    },
    {
        company: 'Садовый центр Агро-опт Махачкала',
        addresses: [
            {
                city: 'Махачкала',
                address: 'Республика Дагестан, г. Махачкала, Федеральное шоссе, 44',
                phone: '+7 (988) 698-88-08',
                district: [43.033507, 47.447028],
                coordOfAddress: [43.036518, 47.451133],
            },
        ],
    },
    {
        company: 'Баланс ООО, Стройка ООО, ИП Евлампиева М.А.',
        website: 'stroyka-market.com',
        addresses: [
            {
                city: 'Рязань',
                address: 'Рязанская область, г.о. Город Рязань, г Рязань, км 185 Километр (окружная Дорога), д. 6',
                district: [54.642238, 39.633356],
                coordOfAddress: [54.644619, 39.637461],
            },
        ],
    },
    {
        company: 'Доминион ООО',
        website: 'www.dmetall.ru',
        addresses: [
            {
                city: 'Старая Купавна',
                address: 'обл. Московская, г. Старая Купавна, ш. Акрихиновское, д. 12',
                district: [55.815913, 38.169354],
                coordOfAddress: [55.818225, 38.173459],
            },
        ],
    },
    {
        company: 'ИДЕЯ ООО',
        website: 'idea-vl.ru',
        addresses: [
            {
                city: 'Владивосток',
                address: 'Приморский край, г. Владивосток, ул. Калинина, д. 275, корп. 1 оф. 63',
                district: [43.088659, 131.900334],
                coordOfAddress: [43.091668, 131.904439],
            },
        ],
    },
    {
        company: 'Империал ООО (Крафт ООО)',
        addresses: [
            {
                city: 'Казань',
                address: 'Республика Татарстан (Татарстан), г Казань, ул Чингиза Айтматова, д. 13, к. 2',
                district: [55.765043, 49.233067],
                coordOfAddress: [55.767357, 49.237173],
            },
        ],
    },
    {
        company: '7 Цветов декор',
        website: '7flowers-decor.ru',
        addresses: [
            {
                city: 'Москва',
                address: 'г. Москва, ул. Молодогвардейская, д.61, стр. 19, эт. 1, ком. 1',
                phone: '+7 (495) 123-45-67',
                district: [55.730193, 37.390739],
                coordOfAddress: [55.732509, 37.394844],
            },
        ],
    },
    {
        company: 'GoldenFlowers Cash&Carry',
        website: 'gfcc.ru',
        addresses: [
            {
                city: 'Москва и МО',
                address: 'Москва и МО, Ленинский район, деревня Апаринки, Логистический парк «Апаринки», 5/2',
                district: [55.566191, 37.758284],
                coordOfAddress: [55.568517, 37.76239],
            },
        ],
    },
    {
        company: 'Мир посуды ЗАО (ДОМАРКЕТ)',
        website: 'domarket.ru',
        addresses: [
            {
                city: 'Омск',
                address: 'Омская Область, г. Омск, ул. 70 Лет Октября, д.25, к.1',
                district: [54.974111, 73.322793],
                coordOfAddress: [54.976473, 73.326898],
            },
        ],
    },
    {
        company: 'Оазис (Оазис декор, ИП Гаджиев, ИП Сидорова)',
        website: 'opt.oasis-gc.ru',
        addresses: [
            {
                city: 'Балашиха',
                address: 'г. Балашиха, ш. Энтузиастов, вл11с3, Западная коммунальная зона',
                district: [55.787487, 37.882953],
                coordOfAddress: [55.789801, 37.887058],
            },
        ],
    },
    {
        company: 'ООО КРОТЕКС, ООО КРОКУС, ООО Твой Дом Кунцево, ООО Твой Дом',
        website: 'tvoydom.ru',
        addresses: [
            {
                city: 'Воронеж',
                address: 'г. Воронеж, проезд Монтажный, корп. 2',
                district: [51.650377, 39.287972],
                coordOfAddress: [51.65293, 39.292077],
            },
            {
                city: 'Калининград',
                address: 'г. Калининград, б-р Солнечный, д. 25',
                district: [54.696314, 20.530583],
                coordOfAddress: [54.698692, 20.534688],
            },
            {
                city: 'Красногорск',
                address: 'г. Красногорск, 65-66 км мкад торгово-выставочный комплекс корп. 4',
                district: [55.7914, 37.207941],
                coordOfAddress: [55.820369, 37.319484],
            },
        ],
    },
    {
        company: 'ООО ПИКО (Пилипака и Компания) сеть строительных гипермаркетов ДЕКОРАДО',
        website: 'decorado.ru',
        addresses: [
            {
                city: 'Нижневартовск,',
                address: 'г. Нижневартовск, (Ханта-Мансийский АО)',
                district: [60.834059, 76.342219],
                coordOfAddress: [60.938545, 76.558902],
            },
        ],
    },
    {
        company: 'Посуда Центр Регион ООО',
        website: 'posudacenter.ru',
        addresses: [
            {
                city: 'Красноярск',
                address: 'Красноярский Край, г. Красноярск, ул. Телевизорная, д. 1, стр. 37',
                district: [56.021719, 92.794909],
                coordOfAddress: [56.024018, 92.799014],
            },
        ],
    },
    {
        company: 'Промстор ООО',
        addresses: [
            {
                city: 'Нарьян-мар',
                address: 'НЕНЕЦКИЙ АВТОНОМНЫЙ ОКРУГ, г.о. Город Нарьян-мар, г Нарьян-мар, ул Рабочая, д. 4А',
                district: [67.640031, 53.04055],
                coordOfAddress: [67.641594, 53.044655],
            },
        ],
    },
    {
        company: 'ООО РЕГИОН, Планета Лета ООО',
        website: 'krasnodar.planeta-leta.com',
        addresses: [
            {
                city: 'Ставрополь',
                address: 'Ставропольский Край, г. Ставрополь, ул. Вокзальная, д. 2',
                district: [45.047087, 41.995458],
                coordOfAddress: [45.049997, 41.999563],
            },
        ],
    },
    {
        company: 'Союзпластик ТПК ООО',
        website: 'souzplastic.ru',
        addresses: [
            {
                city: 'Москва',
                address: 'г. Москва, пр-д 2-й Перова Поля, д. 2, стр. 3',
                district: [55.751715, 37.767034],
                coordOfAddress: [55.754031, 37.771139],
            },
        ],
    },
    {
        company: 'Спецторг-Юг ООО',
        website: 'unidom.ru',
        addresses: [
            {
                city: 'Ростов-на-дону',
                address: 'Ростовская Область, г. Ростов-на-дону, ул. Малиновского, д. 202',
                district: [47.253721, 39.613728],
                coordOfAddress: [47.256516, 39.617833],
            },
        ],
    },
    {
        company: 'Азалия ДЕКОР',
        website: 'azaliadecor.ru',
        addresses: [
            {
                city: 'Москва',
                address: 'г.Москва, 1-й Магистральный пр., 12, стр. 1, м. Шелепиха, Полежаевская',
                district: [55.762221, 37.519845],
                coordOfAddress: [55.764536, 37.52395],
            },
        ],
    },
    {
        company: 'ООО ПРОРОСТ',
        website: 'rostovopt.ru',
        addresses: [
            {
                city: 'Ростов-на-Дону',
                address: 'Ростовская обл., г.Ростов-на-Дону, ст. Ольгинская, ул. Верхне-Луговая, 75А',
                district: [47.185529, 39.954144],
                coordOfAddress: [47.188328, 39.95825],
            },
        ],
    },
    {
        company: 'Дом Рекламы ООО',
        addresses: [
            {
                city: 'Москва',
                address: 'г. Москва, пр-д Сигнальный, д. 19, стр. 1',
                district: [55.85201, 37.588287],
                coordOfAddress: [55.85432, 37.592393],
            },
        ],
    },
    {
        company: 'Елена ООО',
        addresses: [
            {
                city: 'Тверь',
                address: 'Тверская Область, ул. Горького, д.138',
                district: [56.867729, 35.882422],
                coordOfAddress: [56.869978, 35.886528],
            },
        ],
    },
    {
        company: 'Симонян А.В. ИП',
        addresses: [
            {
                city: 'Красногорск',
                address: 'МО, Красногорский бульвар, д.23 кор.2',
                district: [55.823312, 37.36961],
                coordOfAddress: [55.825623, 37.373716],
            },
        ],
    },
    {
        company: 'Калейдоскоп ООО',
        addresses: [
            {
                city: 'Москва',
                address: 'г. Москва, Муниципальный Округ Щукино, ул Маршала Рыбалко, д. 2, к. 6',
                district: [55.795803, 37.491521],
                coordOfAddress: [55.798115, 37.495626],
            },
        ],
    },
    {
        company: 'Крокус ООО',
        addresses: [
            {
                city: 'Краснодар',
                address: 'г. Краснодар, ул Красных Партизан, д. 2/15',
                district: [45.068334, 38.899504],
                coordOfAddress: [45.071243, 38.90361],
            },
        ],
    },
    {
        company: 'КРОНА КОМПАНИЯ, ИП Юдочкин А.Н., ИП Рудых А.К.',
        addresses: [
            {
                city: 'Красноярск',
                district: [55.912057, 92.627517],
                coordOfAddress: [56.010543, 92.852581],
                info: 'Информация о точках продаж представлена на сайте.',
            },
        ],
    },
    {
        company: 'Монолит ООО',
        addresses: [
            {
                city: 'Иркутск',
                address: 'г. Иркутск, улица Иртышская 1а',
                district: [52.309359, 104.242805],
                coordOfAddress: [52.311875, 104.246911],
            },
        ],
    },
    {
        company: 'Престиж Мск ООО, Китеж ООО, ИП Ухванов',
        addresses: [
            {
                city: 'Москва',
                address: 'г. Москва, Муниципальный Округ Якиманка, ул Крымский Вал, д. 3, стр. 2',
                district: [55.726999, 37.605068],
                coordOfAddress: [55.729316, 37.609173],
            },
        ],
    },
    {
        company: 'Семицветик ООО',
        addresses: [
            {
                city: 'Орехово-зуево',
                address: 'МОСКОВСКАЯ ОБЛАСТЬ, г Орехово-зуево, проезд Лермонтова, д. 22, к. 3',
                district: [55.821406, 38.963824],
                coordOfAddress: [55.823717, 38.967929],
            },
        ],
    },
    {
        company: 'Среди цветов Воронеж',
        addresses: [
            {
                city: 'Воронеж',
                address: 'г. Воронеж, ул. Новосибирская 13Б',
                district: [51.608843, 39.233821],
                coordOfAddress: [51.611399, 39.237927],
            },
        ],
    },
    {
        company: 'Фаворит Декор ООО',
        addresses: [
            {
                city: 'Москва',
                address: 'г. Москва, ш. Ленинградское, д. 9 корп. 1',
                district: [55.81432, 37.494485],
                coordOfAddress: [55.816632, 37.498591],
            },
        ],
    },
];
module.exports = addresesData;
